export var SearchList = [
  { labe: '供应商', code: 'supplier', type: 'input', placeholder: '编码/名称' },
  {
    labe: '时间范围',
    code: 'cjTime',
    type: 'daterange'
  },
  {
    labe: '附件类型',
    code: 'type',
    type: 'select',
    option: [
      { label: '工厂直通率', value: '1' },
      { label: '质量协议', value: '2' },
      { label: 'RMA数据', value: '3' },
      { label: '出货检验报告', value: '4' },
      { label: '质量体系资质', value: '5' }
    ]
  }
]
export var SearchData = {
  supplier: '',
  cjTime: '',
  type: ''
}
export var tableField = [
  { selectType: 'selection', width: '', type: 'input' },
  { label: '附件编号', code: 'code', type: 'input' },
  { label: '供应商编码', code: 'supplierCode', type: 'input', width: '100px' },
  { label: '供应商名称', code: 'supplierName', type: 'input', width: '120px' },
  {
    label: '附件类型',
    code: 'fileType',
    type: 'function',
    width: '80px',
    handle: function (index, row) {
      return row.fileType === 1 ? '工厂直通率' : row.fileType === 2 ? '质量协议' : row.fileType === 3 ? 'RMA数据'
        : row.fileType === 4 ? '出货检验报告' : row.fileType === 5 ? '质量体系资质' : row.fileType
    }
  },
  { label: '附件说明', code: 'bz', type: 'input', width: '130px' },
  {
    label: '时间范围',
    type: 'function',
    width: '90px',
    handle: function (index, row) {
      return row.timeScopeStartTime + '~' + row.timeScopeEndTime
    }
  },
  { label: '上传时间', code: 'createDate', type: 'input', width: '90px' },
  {
    label: '附件查看',
    code: 'act',
    width: '80px',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
    ]
  }
]

export var tableData = [
]
