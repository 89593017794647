<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
    <global-table ref="zllist" :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
    <dialog-box ref="addDialog" dialogWidth="820px" :formData1="showFormData1" :formData="showFormData" :dialogShow="showDialogShow" @handleClose='handleClose' @confirmAdd= "handleClose"  title="查看附件" componentName="ShowFileList"
      :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'confirmAdd', type:'primary', size:'mini'}]">
    </dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData } from './js/setting.js'
import { request } from '@/assets/js/http.js'
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import dayjs from 'dayjs'
export default {
  components: { ...Component.components, GlobalTable },
  name: 'QualityList',
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      showDialogShow: false,
      showFormData: {},
      showFormData1: {},
      paginationData: {
        total: 0
      },
      pageNum: 1,
      menudata: this.$store.state.ViewMenuData.menuData,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.myBidData()
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  },
  methods: {
    buttonClick: function (data) {
      const dellist = []
      for (const item of this.$refs.zllist.selectData) {
        dellist.push(item.id)
      }
      this.$confirm('是否删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request('/api/quality/data/delete', 'delete', dellist).then((res) => {
          if (res.code === '200') {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.myBidData()
          }
        })
      }).catch(() => {
        this.$message({
          message: '操作已取消！',
          type: 'info'
        })
      })
    },
    handleClose () {
      this.showDialogShow = false
    },
    myBidData () {
      var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
      if (pageSize < 10) {
        pageSize = 10
      }
      let cjksTime = ''
      let cjjsTime = ''
      if (this.childData.searchData.cjTime) {
        cjksTime = dayjs(this.childData.searchData.cjTime[0]).format('YYYY-MM-DD HH:mm:ss')
        cjjsTime = dayjs(this.childData.searchData.cjTime[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      const obj = {
        supplier: this.childData.searchData.supplier,
        startTime: cjksTime,
        endTime: cjjsTime,
        type: this.childData.searchData.type
      }
      request('/api/quality/data/list?' + 'pageNum=' + this.pageNum + '&' + 'pageSize=' + pageSize, 'GET', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          if (this.paginationData.total === 0) {
            this.paginationData = { pageSize: pageSize, total: res.data.total }
          } else {
            this.paginationData.total = res.data.total
          }
        }
      })
    },
    tableAction: function (index, data, clickBtn) {
      request(`/api/quality/data/getById/${data.id}`, 'get').then((res) => {
        if (res.code === '200') {
          this.showFormData = res.data
          this.showFormData1 = data
          this.showDialogShow = true
        }
      })
    },
    onSearch (data) {
      this.myBidData()
    },
    clearSearch (data) {
      this.myBidData()
    },
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
